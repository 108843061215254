import React, { useState } from 'react';

import { Text } from 'components';

import styled from 'styled-components';
import { tablet } from 'styles/breakpoints';

import SMS from '../../assets/icons/sms.svg';
import Location from '../../assets/icons/location.svg';
import Home from '../../assets/icons/home.svg';

import { config } from 'config';

import Layout from 'layouts/Layout';

const TitleContainer = styled.div`
  padding: 3rem 1rem;
  display: flex;
  justify-content: center;
  @media ${tablet} {
    padding: 1.5rem 1rem;
  }
`;

const StyledTitle = styled(Text)`
  font-size: 2.375rem;
  @media ${tablet} {
    font-size: 1.625rem;
  }
`;

const ContactSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1204px;
  margin: auto;
  padding: 4.5rem 1rem;
  @media ${tablet} {
    flex-direction: column;
    gap: 3rem;
    padding: 3rem 1rem;
  }
`;

const LeftSide = styled.div``;

const LeftSideTitle = styled(Text)`
  font-size: 1.75rem;
  font-weight: 600;
  margin-bottom: 1.875rem;
  @media ${tablet} {
    font-size: 1.5rem;
  }
`;

const HomeLogo = styled(Home)`
  height: 32px;
  width: 32px;
  margin-right: 1.5rem;
`;

const LocationLogo = styled(Location)`
  height: 32px;
  width: 32px;
  margin-right: 1.5rem;
`;

const SmsLogo = styled(SMS)`
  height: 32px;
  width: 32px;
  margin-right: 1.5rem;
`;

const ItemWrap = styled.div`
  display: flex;
  padding-bottom: 2rem;
`;

const TextWrap = styled.div``;

const Title = styled(Text)`
  font-size: 1.125rem;
  font-weight: 600;
`;

const LeftSubTitle = styled(Text)`
  padding-top: 0.688rem;
`;

const RightSide = styled.div``;

const RightSideTitle = styled(Text)`
  font-size: 1.75rem;
  font-weight: 600;
  margin-bottom: 1.875rem;
  @media ${tablet} {
    font-size: 1.5rem;
  }
`;

const ContactMobile = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    honeypot: '',
  });
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const updateInput = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = event => {
    event.preventDefault();

    if (formData.honeypot === '') {
      sendEmail();
    } else {
      setError(true);
    }
  };

  const sendEmail = async () => {
    try {
      await fetch(config.FIREBASE_MAILING, {
        method: 'POST',
        body: JSON.stringify({
          email: formData.email,
          name: formData.name,
          message: formData.message,
          funnelCode: 'LC',
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      setFormData({
        name: '',
        email: '',
        message: '',
        honeypot: '',
      });
      setError(false);
      setSuccess(true);
    } catch {
      setSuccess(false);
      setError(true);
    }
  };

  return (
    <Layout isMobileLink>
      <TitleContainer>
        <StyledTitle>
          Hello, how <strong>can we help you?</strong>
        </StyledTitle>
      </TitleContainer>

      <ContactSection>
        <LeftSide>
          <LeftSideTitle>Get in touch with us</LeftSideTitle>
          <ItemWrap>
            <HomeLogo />
            <TextWrap>
              <Title>Company</Title>
              <LeftSubTitle>Medical Score UAB</LeftSubTitle>
            </TextWrap>
          </ItemWrap>

          <ItemWrap>
            <HomeLogo />
            <TextWrap>
              <Title>Company code</Title>
              <LeftSubTitle>305445861</LeftSubTitle>
            </TextWrap>
          </ItemWrap>

          <ItemWrap>
            <LocationLogo />
            <TextWrap>
              <Title>Address of registration</Title>
              <LeftSubTitle>
                J. Zemgulio str. 46, Panevezys, Lithuania
              </LeftSubTitle>
            </TextWrap>
          </ItemWrap>

          <ItemWrap>
            <LocationLogo />
            <TextWrap>
              <Title>Address</Title>
              <LeftSubTitle>
                Antakalnio str. 17, Vilnius, Lithuania
              </LeftSubTitle>
            </TextWrap>
          </ItemWrap>

          <ItemWrap>
            <SmsLogo />
            <TextWrap>
              <Title>Email</Title>
              <LeftSubTitle>hello@thelastingchange.com</LeftSubTitle>
            </TextWrap>
          </ItemWrap>

          <LeftSideTitle>Subscription services provider</LeftSideTitle>

          <ItemWrap>
            <HomeLogo />
            <TextWrap>
              <Title>Karma Processing Incorporated</Title>
            </TextWrap>
          </ItemWrap>

          <ItemWrap>
            <LocationLogo />
            <TextWrap>
              <Title>1000 West Street, Suite 1200</Title>
              <LeftSubTitle> Wilmington, Delaware 19801</LeftSubTitle>
            </TextWrap>
          </ItemWrap>
        </LeftSide>
        <RightSide>
          <RightSideTitle>Leave us a message</RightSideTitle>
          <form onSubmit={handleSubmit}>
            <StyledLabel>
              Your email address <StyledStar>*</StyledStar>
            </StyledLabel>
            <HoneyPot
              name="honeyPot"
              onChange={updateInput}
              value={formData.honeyPot || ''}
            />
            <StyledInput
              type="email"
              name="email"
              required={true}
              onChange={updateInput}
              value={formData.email || ''}
            />

            <StyledLabel>
              Subject <StyledStar>*</StyledStar>
            </StyledLabel>
            <StyledInput
              type="text"
              name="name"
              required={true}
              onChange={updateInput}
              value={formData.name || ''}
            />

            <StyledLabel>
              Description <StyledStar>*</StyledStar>
            </StyledLabel>
            <StyledTextArea
              name="message"
              onChange={updateInput}
              value={formData.message || ''}
            />

            <Note>
              Please enter the details of your request. A member of our team
              will respond as soon as possible.
            </Note>
            {success ? (
              <SuccessText>Message was sent successfully!</SuccessText>
            ) : null}
            {error ? (
              <ErrorText>There was an issue sending your email.</ErrorText>
            ) : null}
            <StyledButton type="submit">Submit</StyledButton>
          </form>
        </RightSide>
      </ContactSection>
    </Layout>
  );
};

export default ContactMobile;

const HoneyPot = styled.input`
  display: none;
`;

const StyledStar = styled.span`
  color: #e53535;
`;

const SuccessText = styled(Text)`
  padding-bottom: 12px;
  font-size: 0.9rem;
  color: rgb(6, 194, 112);
`;

const ErrorText = styled(Text)`
  padding-bottom: 12px;
  font-size: 0.9rem;
  color: rrgb(255, 59, 59);
`;

const Note = styled.div`
  color: #1c1c28;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.024em;
  line-height: 140%;
  margin: -20px 0 47px;
  opacity: 0.8;
`;

const StyledButton = styled.button`
  align-items: center;
  background: #1c1c28;
  border: 0;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  letter-spacing: -0.024em;
  min-height: 48px;
  min-width: 240px;
  padding: 14px 16px;
  text-align: center;
  transition: all 0.3s ease;
  border-radius: 8px;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  @media ${tablet} {
    width: 100%;
  }
`;

const StyledLabel = styled.label`
  color: #1c1c28;
  display: block;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.024em;
  line-height: 140%;
  margin-bottom: 12px;
`;

const StyledInput = styled.input`
  border-radius: 8px;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  background: #fff;
  border: 1px solid #e4e4eb;
  color: #1c1c28;
  display: block;
  height: 48px;
  margin: 0 0 32px;
  outline: none;
  padding: 8px 16px;
  width: 100%;
  -webkit-appearance: none;
`;

const StyledTextArea = styled.textarea`
  border-radius: 8px;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  background: #fff;
  border: 1px solid #e4e4eb;
  color: #1c1c28;
  display: block;
  height: 48px;
  margin: 0 0 32px;
  outline: none;
  padding: 8px 16px;
  width: 100%;
  min-height: 144px;
`;

const StyledText = styled(Text)`
  margin-bottom: 2rem;
`;
